import React, {useEffect, useRef, useState} from "react";
import {get} from "sodon-http/API";
import {API_URL} from "../../constans/API_URL";
import {Link, useNavigate, useParams} from "react-router-dom";
import PlayerSection from "./PlayerSection";
import ReactSplayer from "../../components/splayer/ReactSplayer";

export default function Player() {

    const navigate = useNavigate();
    const {episodeId} = useParams();

    const [movieId, setMovieId] = useState(null);
    const [movieType, setMovieType] = useState(null);
    const [episodeData, setEpisodeData] = useState(null);

    const [currentEpisodeId, setCurrentEpisodeId] = useState(episodeId);
    const [selectedEpisodeId, setSelectedEpisodeId] = useState(null);

    const hasNextEpisodeRef = useRef(false);
    const playerRef = useRef(null);

    const [isPlayerSectionVisible, setIsPlayerSectionVisible] = useState(false);
    const [videoQualities, setVideoQualities] = useState([{label: null, type: null, src: ''}]);

    const [title, setTitle] = useState("");
    const [chapterTitle, setChapterTitle] = useState("");

    const [nextEpisode, setNextEpisode] = useState(null);

    const [isExpired, setIsExpired] = useState(false);

    useEffect(() => {
        checkExpired();
    }, [])

    useEffect(() => {
        if (playerRef.current) {
            if (isPlayerSectionVisible)
                playerRef.current.eventHandler._pauseVideo();
            else
                playerRef.current.eventHandler._playVideo();
        }
    }, [isPlayerSectionVisible])

    useEffect(() => {
        console.log(currentEpisodeId)
        getEpisodeQuality(currentEpisodeId);
        getMovieData();
        fetchNextEpisodeData();
    }, [currentEpisodeId]);

    useEffect(() => {
        if (selectedEpisodeId) {
            setCurrentEpisodeId(selectedEpisodeId);
            setIsPlayerSectionVisible(false);
            getEpisodeQuality(selectedEpisodeId);
            getMovieData();
        }
    }, [selectedEpisodeId]);

    useEffect(() => {

    }, [videoQualities]);

    useEffect(() => {
        // console.log("playerRef.current", playerRef.current);
    }, [playerRef.current]);

    useEffect(() => {
        if (episodeData) {
            const alias = episodeData.movie.type.alias;
            if (alias === "anime" || alias === "movieSerial") {
                setTitle(episodeData.movie.name);
                setChapterTitle(`#${episodeData.episodeNumber} | ${episodeData.name}`);
            } else {
                setTitle(`${episodeData.name}`);
                setChapterTitle("");
            }
        }
    }, [episodeData]);

    const checkExpired = async () => {
        const response = await get(`${API_URL}/api/movie/user/subscription/checkExpired`);
        if (response.success) {
            if (response.payload)
                setIsExpired(true);
            else
                setIsExpired(false);
        }
    }


    const getMovieData = async () => {
        try {
            const response = await get(`${API_URL}/api/movie/episode/${currentEpisodeId}`);
            if ((await response).success) {
                setMovieId((await response).payload.movie.id)
                setMovieType((await response).payload.movie.type.alias);
                setEpisodeData(await response.payload)
                if (playerRef?.current?.video?.current) {
                    const video = playerRef?.current?.video?.current;
                    if (response.payload.image)
                        video.setAttribute("poster", `${API_URL}/resource/${response.payload.image.path}`);
                    else
                        video.removeAttribute("poster")
                }
            }
        } catch (e) {

        }
    }




    const getEpisodeQuality = async (id) => {
        try {
            const response = await get(`${API_URL}/api/movie/episode/${id}`);
            if (response.success) {
                setCurrentEpisodeId(response.payload.id);
                const newArr = [];
                if (response.payload.video480) {
                    newArr.push({
                        src: `${API_URL}/movie/episode/${id}/video/480/url`,
                        type: "video/mp4",
                        label: '480p',
                    });
                }
                if (response.payload.video720) {
                    newArr.push({
                        src: `${API_URL}/movie/episode/${id}/video/720/url`,
                        type: "video/mp4",
                        label: "720"
                    });
                }
                if (response.payload.video1080) {
                    newArr.push({
                        src: `${API_URL}/movie/episode/${id}/video/1080/url`,
                        type: "video/mp4",
                        label: "1080"
                    });
                }
                setVideoQualities(newArr);
            }
        } catch (e) {

        }
    };

    const fetchNextEpisodeData = async () => {
        try {
            const next = await get(`${API_URL}/api/movie/episode/${currentEpisodeId}/next`);
            if (next) {
                if (next.payload.type !== "ERROR") {
                    hasNextEpisodeRef.current = true;
                    await setNextEpisode(next.payload);
                } else {
                    hasNextEpisodeRef.current = false;
                }
            }
        } catch (e) {
            console.warn("An error occurred while fetching next episode data: ", e);
        }
    }

    const handleClickOnEpisodes = () => {
        setIsPlayerSectionVisible(!isPlayerSectionVisible);
    };

    const handleClickOnNextEpisode = () => {
        if (nextEpisode) {
            if (nextEpisode.video480) {
                getEpisodeQuality(nextEpisode.id);
                setCurrentEpisodeId(nextEpisode.id)
            }
        }
    }


    if (currentEpisodeId) {
        return (
            <div style={{display: "flex", flexFlow: "column", flex: 1}}>
                {
                    !isExpired ?
                        <ReactSplayer
                            ref={playerRef}
                            backNavigation={() => navigate(-1)}
                            sources={videoQualities}
                            onClickEpisodes={handleClickOnEpisodes}
                            onClickNextEpisode={handleClickOnNextEpisode}
                            loadstart={e => {
                            }}
                            isSeries={movieType && movieType === "anime"}
                            hasNextEpisode={hasNextEpisodeRef?.current}
                            videoTitle={title}
                            chapterTitle={chapterTitle}
                            error={(e) => {
                                console.log("Error while requesting for the media: ", e)
                            }}
                        >
                            <div style={{position: "absolute", right: 0, zIndex: 0, pointerEvents: "none"}}>
                                <div className="drawer-container">
                                    <div className={`player-section-drawer ${isPlayerSectionVisible ? 'open' : ''}`}>
                                        {movieId && (
                                            <PlayerSection
                                                close={handleClickOnEpisodes}
                                                movieId={movieId}
                                                currentEpisodeId={currentEpisodeId}
                                                onSelectEpisode={id => {
                                                    console.log(`PRESSED EPISODE ID: ${id}`)
                                                    setSelectedEpisodeId(id)
                                                }}
                                            />
                                        )}
                                    </div>
                                    {isPlayerSectionVisible && <div className="backdrop" onClick={handleClickOnEpisodes}></div>}
                                </div>
                            </div>
                        </ReactSplayer>
                        :
                        <>
                            <div style={{flex: 1, display: "flex", justifyContent: "center", alignItems: "center", height: "100%", flexWrap: "wrap"}}>
                                <div>
                                    <span style={{color: "rgb(102, 102, 102)", fontSize: 24}}>Та хандивлагч гишүүн болсноор үзэх боломжтой </span>
                                    <Link to={"/qpay"}>
                                        <button className={"button-md button-primary"}>
                                            <i className={"fa fa-heartbeat"}></i>
                                            Хандивлагч гишүүн болох
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </>
                }
            </div>
        )
    }
}
