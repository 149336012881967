import React, {useEffect, useState} from 'react'
import ArrowRight from "../../components/icons/ArrowRight";
import SectionEpisode from "../episode/EpisodeSection";
import {get} from "sodon-http/API";
import {API_URL} from "../../constans/API_URL";
import ArrowDown from "../../components/icons/ArrowDown";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import Close from "../../components/icons/Close";

export default function PlayerSection({movieId, currentEpisodeId, onSelectEpisode, close}) {


    const [episodes, setEpisodes] = useState([]);
    const [movieData, setMovieData] = useState([]);
    const [sections, setSections] = useState([]);
    const [selectedSection, setSelectedSection] = useState(null);
    const [sectionEpisodes, setSectionEpisodes] = useState([]);

    const [types, setTypes] = useState([]);
    const [ovaEpisodes, setOvaEpisodes] = useState([]);
    const [songEpisodes, setSongEpisodes] = useState([]);
    const [animeMovie, setAnimeMovie] = useState([]);

    useEffect(() => {
        setTypes([{type: "episode", id: 1,}, {type: "ova", id: 2,}, {type: "movie", id: 3,}, {type: "song", id: 5,},])
    }, []);

    useEffect(() => {
        fetchMovieData();
    }, [movieId])

    useEffect(() => {
        if (types && types.length > 0) {
            tryAllEpisodeTypes();
        }
    }, [types])

    useEffect(() => {
        fetchSelectedSectionEpisode();
    }, [selectedSection]);


    useEffect(() => {
        fetchSelectedSectionEpisode();
    }, [types])



    useEffect(() => {
        if (movieData) {
            if (movieData.sections && movieData.sections.length >= 1) {
                setSections(movieData.sections);
            }
            if (movieData.sections && movieData.sections.length === 0) {
                fetchEpisodes();
            }
        }
    }, [movieData]);

    const onEpisodeSelect = (val) => {
        if (val) {
            onSelectEpisode(val)
        }

    }

    // Movie ийн мэдээлэлийг эхний байдлаар авч байна
    const fetchMovieData = async () => {
        try {
            const response = await get(`${API_URL}/api/movie/${movieId}`);
            if ((await response).success) {
                setMovieData((await response).payload)
            }
        } catch (e) {

        }
    }


    const fetchSelectedSectionEpisode = async () => {
        if (selectedSection !== null){
            try {
                const response = await get(`${API_URL}/api/movie/${movieId}/${selectedSection}/episode`);
                if ((await response).success) {
                    setSectionEpisodes((await response).payload)
                }
            } catch (e) {

            }
        }
    }


    const toggleSectionEpisodes = (item) => {
        if (selectedSection === item.id){
            setSelectedSection(null)
        }else {
            setSelectedSection(item.id)
        }
    };

    const tryAllEpisodeTypes = async () => {
        for (let i = 0; i < types.length; i++) {
            if (types[i].type !== "episode") {
                await fetchEpisodesByType(types[i]);
            }
        }
    }

    // Энгийн ангиудаас бусад төрлүүдийг авч байна
    const fetchEpisodesByType = async (type) => {
        try {
            const response = await get(`${API_URL}/api/movie/episode/list?movieId=${movieId}&typeId=${type.id}`);
            if ((await response).success) {
                const data = response.payload
                if (type.type === "movie")
                    setAnimeMovie(data);
                else if (type.type === "song")
                    setSongEpisodes(data);
                else if (type.type === "ova")
                    setOvaEpisodes(data);
            }
        } catch (e) {

        }
    }

    // Энгийн ангиуйдын мэдээлэл татах хүсэлт
    const fetchEpisodes = async () => {
        try {
            const response = await get(`${API_URL}/api/movie/episode/list?movieId=${movieId}&typeId=1`);
            if ((await response).success) {
                setEpisodes((await response).payload)
            }
        } catch (e) {

        }
    }


    function renderMovieSections() {
        return (
            <TabPanel>
                <div className={`player-episodes ${selectedSection !== null ? 'active' : ''}`}>
                    {sections.length === 0 && episodes.length > 0 &&
                        <div style={{overflowY: "auto"}}>
                            {episodes.map((item, index) => (
                                <SectionEpisode key={index} item={item} onClickEpisode={onEpisodeSelect} currentEpisodeId={currentEpisodeId}/>
                            ))}
                        </div>
                    }
                    {/* Хэрэв бүлэгтэй movie бол */}
                    {sections && sections.map((item, index) => (
                        <div>
                            <div key={`${item.id}`}
                                 className={`player-section ${selectedSection === item.id ? 'active' : ''}`}
                                 onClick={() => toggleSectionEpisodes(item)}>
                                <div className={"title"}>
                                    {item.name}
                                </div>
                                {selectedSection === item.id ?
                                    <div>
                                        <ArrowDown/>
                                    </div>
                                    :
                                    <div>
                                        <ArrowRight/>
                                    </div>

                                }
                            </div>
                            <div>
                                {selectedSection === item.id && (
                                    <div className="player-section-body-active">
                                        <div>{sectionEpisodes.length > 1 ?
                                            <div>
                                                {sectionEpisodes.map((item, index) => (
                                                    <SectionEpisode key={index} item={item} onClickEpisode={onEpisodeSelect} currentEpisodeId={currentEpisodeId}/>
                                                ))}
                                            </div>
                                            :
                                            <div>
                                                Анги байхгүй байна
                                            </div>
                                        }
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </TabPanel>
        );
    }
    function renderOVA() {
        return (
            <TabPanel>
                <div className={"shahas"}>
                    {
                       ovaEpisodes &&  ovaEpisodes.length > 1 ?
                            (
                                ovaEpisodes.map((item, index) => (
                                    <SectionEpisode key={index} item={item} onClickEpisode={onEpisodeSelect} currentEpisodeId={currentEpisodeId}/>
                                ))
                            )
                            :
                            (
                                <div style={{color: "#C1BFBF"}}>
                                    Тусгай анги байхгүй
                                </div>
                            )
                    }
                </div>
            </TabPanel>
        );
    }
    function renderSpecialMovie() {
        return (
            <TabPanel>
                <div className={"shahas"}>
                    {
                        animeMovie && animeMovie.length > 1 ?
                            (
                                animeMovie.map((item, index) => (
                                    <SectionEpisode key={index} item={item} onClickEpisode={onEpisodeSelect} currentEpisodeId={currentEpisodeId}/>
                                ))
                            )
                            :
                            (
                                <div style={{color: "#C1BFBF"}}>
                                    Кино байхгүй
                                </div>
                            )
                    }
                </div>
            </TabPanel>
        );
    }
    function renderSong() {
        return (
            <div>
                <TabPanel>
                    <div className={"shahas"}>
                        {
                            songEpisodes && songEpisodes.length > 1 ?
                                (
                                    songEpisodes.map((item, index) => (
                                        <SectionEpisode key={index} item={item} onClick={onEpisodeSelect} currentEpisodeId={currentEpisodeId}/>
                                    ))
                                )
                                :
                                (
                                    <div style={{color:"#C1BFBF"}}>
                                        Дуу байхгүй
                                    </div>
                                )
                        }
                    </div>
                </TabPanel>
            </div>
        );
    }


    return (
        <>
            <div className={"player-section-layout"}>
                <div className={"player-section-header"}>
                    <div>
                        {
                            movieData.name &&
                            <div className={"title"}>{movieData?.name}</div>
                        }
                        {
                            movieData.year &&
                            <div className={"year"}>{movieData?.year}</div>
                        }

                    </div>
                    {movieData?.totalNumber &&
                        <div className={"title"}>{movieData?.totalNumber} анги</div>

                    }
                </div>
                <Tabs className={"player_section__tabs"} selectedTabClassName={"player_section__tab--selected"}>
                    <TabList className={"player_section__list"}>
                        <Tab className={"player_section__tab"}>Ангиуд</Tab>
                        {
                            ovaEpisodes.length > 0 &&
                            <Tab className={"player_section__tab"}>Тусгай анги</Tab>
                        }
                        {
                            animeMovie.length > 0 &&
                            <Tab className={"player_section__tab"}>Кино</Tab>
                        }
                        {
                            songEpisodes.length > 0 &&
                            <Tab className={"player_section__tab"}>Дуу</Tab>
                        }
                    </TabList>
                    {renderMovieSections()}
                    {ovaEpisodes.length > 0 && renderOVA()}
                    {animeMovie.length > 0 && renderSpecialMovie()}
                    {songEpisodes.length > 0 && renderSong()}
                </Tabs>
            </div>
        </>

    )
}
