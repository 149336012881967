import React, {useEffect, useState} from "react";
import SkeletonLoader from "../../components/skeleton-loader/skeleton-loader";


export default function EpisodeSection({item, onClickEpisode, currentEpisodeId}) {

    const episode = item;
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 300);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        console.log("currentEpisodeId 2", currentEpisodeId)
    }, [currentEpisodeId]);

    useEffect(() => {

    }, [onClickEpisode]);



    if (currentEpisodeId && currentEpisodeId === episode.id){
        return (
            <div>
                <div className={"episode__section grid grid-cols-7"}
                     onMouseUp={() => onClickEpisode(episode.id)}>
                    <div className={"col-span-3"}>
                        {
                            loading ? (
                                <div>
                                    <SkeletonLoader/>
                                </div>
                            ) : (
                                <div>
                                    <div className="img" style={{position: "relative"}}>
                                        <img
                                            src={`https://www.mnfansubs.net/resource/${episode.image.name}.${episode.image.ext}`}
                                            alt=""
                                            onLoad={() => setLoading(false)}
                                            style={{width: "100%"}}
                                        />
                                        <div style={{
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%, -50%)"
                                        }}>
                                            <svg fill="none" viewBox="0 0 24 24" height="50px" width="50px">
                                                <path fill="#FF4418" d="M11 7H8v10h3V7zM13 17h3V7h-3v10z"/>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        <div className={"duration"}>
                            {
                                episode.duration && <span>{episode?.duration}</span>
                            }
                        </div>
                    </div>
                    <div className={"col-span-4"}>
                        <div className={"ep"} style={{color: "#FF4418"}}><span>{episode?.episodeNumber}-р анги</span></div>
                        <div className={"title"} style={{color: "#FF4418"}}>{episode?.name}</div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <>
                <div className={"episode__section grid grid-cols-7"}
                     onMouseUp={() => {
                         onClickEpisode(episode.id)
                     }}>
                <div className={"col-span-3"}>
                        {
                            loading ? (
                                <div>
                                    <SkeletonLoader/>
                                </div>
                            ) : (
                                <div>
                                    {
                                        episode.image &&
                                            <div className={"img"}>
                                                <img
                                                    src={`https://www.mnfansubs.net/resource/${episode.image.name}.${episode.image.ext}`}
                                                    alt=""
                                                    onLoad={() => setLoading(false)}
                                                />
                                            </div>

                                    }

                                </div>
                            )
                        }
                    <div className={"duration"}>
                        {
                            episode.duration && <span>{episode?.duration}</span>
                        }
                    </div>
                </div>
                    {
                        episode.episodeNumber ?
                            <div className={"col-span-4"}>
                                <div>
                                    {
                                        <div className={"ep"}><span>{episode?.episodeNumber}-р анги</span></div>
                                    }
                                </div>
                                <div className={"title"}>{episode?.name}</div>
                            </div>
                            :
                            <div className={"col-span-4"} style={{display:"flex", alignItems:"center"}}>
                                <div className={"title"}>{episode?.name}</div>
                            </div>
                    }
                </div>
            </>

        )

    }
}
